.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 290;
    top: 0;
    right: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 120px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

}

.sidenavBtn {
    padding: 8px 8px 8px 32px;
    /* font-size: 25px; */
    color: black;
    display: block;
    transition: 0.3s;
    cursor: pointer;
}

.sidenavBtn:hover {
    color: #dedada;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    left: 25px;
    font-size: 40px;
    margin-right: 50px;
    padding-top: 50px;
    cursor: pointer;
}

.hr {
    width: 60%;
    height: 1px;
    margin-right: 20%;
    background-color: rgb(176, 176, 176);
}

.closebtn:hover {
    color: #fe7b7b;
}

#main1 {
    transition: 0.5s;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenavBtn {
        font-size: 18px;
    }
}

#sortAndFilter {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    width: 180px;
    line-height: 230%;
    font-size: 22px;
    cursor: pointer;
    position: fixed;
    margin-top: -7vh;
    z-index: 300;
    background: linear-gradient(90deg, #e57373, #c00000);
    color: white;
    transform: 0.5s;
}

#sortAndFilter:hover {
    background: linear-gradient(90deg, white, rgb(220, 200, 200));
    /* background-color: rgb(220, 200, 200); */
    color: #c00000;
}

.check {
    text-align: right;
    margin-right: 10%;
    cursor: pointer;
}

.check:hover {
    cursor: pointer;
}

.checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: right;
    font-size: 95%;
    margin-right: -30%;
    cursor: pointer;
    position: absolute;
}

.checkbox-custom {
    position: absolute;
    top: 5px;
    right: -20px;
    height: 20px;
    width: 20px;
    background-color: #e8dcdc;
    border-radius: 3px;
    color: transparent;
    line-height: 20px;

}

.checkbox-container:hover .checkbox-custom {
    background-color: #ccc;
}

.checkbox1:checked+.checkbox-custom,
.checkbox2:checked+.checkbox-custom {
    background-color: #fe7b7b;
    color: #c00000;
}

.checkbox1,
.checkbox2 {
    display: none;
}

.allUl {
    direction: ltr;
    left: 0%;
    cursor: pointer;
    position: fixed;
    z-index: 100;
    margin-top: -10vh;
    transition: display 0.6s;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: max-content;
    max-width: 220px;
    background-color: rgb(245, 238, 238);
    transition: display 0.6s;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    display: inline-block;
}

li div {
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
    cursor: pointer;
}

/* Change the link color on hover */
.liDiv:hover {
    background: linear-gradient(90deg, #e57373, #c00000);
    color: white;
}

#searchB {
    border-bottom: #c00000 2px solid;
    width: 80%;
}

.dateSearch:focus {
    outline: none;
}

.notBus {
    margin-top: -12%;
}

/* option {
    width: 90%;
    margin-right: -10%;
} */

@media (max-width: 768px) {
    ul {
        display: none;
    }
}