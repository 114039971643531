#modal {
    position: fixed;
    z-index: 800;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.334);
}

#formCon111 {
    width: 100%;
    background-color: black;
    height: 64vh;
    margin-top: 18vh;
    color: white;
}

.input111 {
    border-radius: 0px 0px 0px 0px;
    background-color: transparent;
    color: white;
    border: none;
    border-bottom: #FF7D7D 2px solid;
    display: block;
    width: 300px;
    margin-top: 1%;
    padding: 5px;
    margin-right: auto;
    margin-left: auto;
    transition: 1s;
}

.input111:hover {
    /* border-radius: 5px 5px 5px 5px; */
    border-bottom: white 2px solid;
}

.input111:focus {
    outline: none;
    /* border: #FF7D7D 2px solid; */
    width: 40%;
}

.close:hover {
    color: #FF7D7D;
    font-size: large;
}
