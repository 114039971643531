.backButton {
    cursor: pointer;
    margin-top: -16%;
    font-size: 250%;
    margin-right: 11%;
    z-index: 200;
    user-select: none;
}

.nextButton {
    cursor: pointer;
    margin-top: -16%;
    font-size: 250%;
    margin-left: 11%;
    z-index: 200;
    user-select: none;
}

.nextButton:hover,
.backButton:hover {
    color: #C00000;
}

.k1 {
    position: absolute;
    left: 19%;
    width: 0%;
    /* margin-left: 20%; */
    height: 1px;
    background-color: #C00000;
    top: 70%;
    transition: 2s;
}

.k11 {
    width: 61%;
}

.k2 {
    width: 1px;
    margin-right: 81%;
    height: 0px;
    background-color: #C00000;
    margin-top: -1.1%;
}

.k22 {
    transition: 1s;
    height: 430px;
}