  /* clip-path: xywh(0 5px 100% 75% round 15% 0); */
  /* background: linear-gradient(to left, #afeaff, #f6c5a4, #ffafaf); */

  .homepage {
    margin-top: 0%;
  }

  .pic {
    background-color: black;
    background-image: url(../../../pic/ready.png);
    background-position: top left;
    transition: background-position 0.4s ease-in-out;
    background-repeat: no-repeat;
    background-size: 110%;
    height: 60vh;
    user-select: none;
  }

  .card {
    display: inline-block;
    vertical-align: top;
    /* מיישר כלפי מעלה */
    width: 17vh;
    height: 120px;
    margin: 2%;
  }

  .icon {
    width: 80px;
    height: 80px;
    font-weight: lighter;
  }

  .card:hover {
    color: #C00000;
    cursor: pointer;
  }

  .underline3 {
    bottom: 0;
    width: 0;
    height: 3px;
    background-color: #C00000;
    transform: translateX(0%) translateY(0%);
    transition: width 0.3s ease-in-out;
  }

  .visible2 {
    transform: translateX(-50%) translateY(0%);
  }

  .visible3 {
    transform: translateX(-1200%) translateY(-750%);
    width: 4%;
    height: 4px;
  }

  .card:hover .underline3 {
    width: 20%;
    margin-right: 30%;
  }

  /* ----------------------------start top button---------------------------- */
  #topBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    background-color: #C00000;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
  }

  #topBtn:hover {
    background-color: black;
  }

  /* ----------------------------end top button---------------------------- */

  .animate {
    animation-duration: 3s;
    width: 50%;
    text-align: center;
    padding: 3%;
    font-family: 'IBM Plex Sans Hebrew';
  }

  .animate__bounceInLeft {
    margin-right: 0;
  }

  .animate__bounceInRight {
    margin-right: 50%;
  }

  .p1 {
    background-image: url(../../../pic/pic2.png);
    background-size: cover;
    background-position: center;
  }

  .p2 {
    background-image: url(../../../pic/pic3.png);
    background-size: cover;
    background-position: center;
  }

  .inPic {
    width: 30%;
    margin-top: 20vh;
  }

  .divNitshad {
    margin-top: 6%;
  }

  .kol2 {
    display: none;
  }

  .textRashi {
    font-size: 130%;
  }

  @media only screen and (max-width: 1000px) {
    .pic {
      width: 100%;
      background-size: 150% 85%;
      height: 43vh;
      /* padding-bottom: 5vh; */
      margin-bottom: 10vh;
      /* margin-top: 5vh; */
      font-size: 60%;
      padding-right: 24%;
      padding-left: 24%;
      background-position: bottom left;
    }

    .textRashi {
      font-size: 180%;
    }

    .kol {
      display: none;
    }

    .kol2 {
      display: block;
    }

    .inPic {
      width: 70%;
    }

    .pic:hover {
      background-position: top left;
    }

    .homepage {
      font-size: 90%;
    }

    .divNitshad {
      margin-top: 10%;
      /* font-size: 60%; */
      padding-right: 10%;
      padding-left: 10%;
      padding-bottom: 5%;
    }
  }

  .divNitshad1 {
    background-color: white;
    color: black;
    border: black 4px solid;
  }

  .divNitshad1:hover {
    background-color: black;
    color: white;
    border-color: white;
  }