#PicBack {
    background-image: url(../../../pic/logo/444.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.manag {
    position: absolute;
    left: 1%;
    top: 9%;
    /* font-size: 120%; */
    /* width: 5%;
    height: 5%; */
}

.manag:hover {
    /* color: #c00000; */
    cursor: pointer;
}

.pInDiv p {
    display: inline-block;
    margin: 0px;
}


.questions {
    margin-top: 5%;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    line-height: 7vh;
    user-select: none;
    margin-bottom: 5%;
}

.questionCon {
    /* background-color: #c00000; */
    border: #c00000 1px solid;
    border-left: none;
    border-right: none;
    height: 10vh;
    line-height: 10vh;
}

.answerCon {
    /* background-color: #c00000; */
    border: #c00000 1px solid;
    border-top: none;
    background-color: #dabfbf;
    height: 10vh;
    line-height: 10vh;
}

.k1 {
    direction: ltr;
    width: 0%;
    height: 1px;
    background-color: #C00000;
    margin-top: -7vh;
    transition: 2s;
}

.k11 {
    width: 60%;
}

.songs {
    width: 900px;
    max-width: 100%;
    height: 12vh;
    background-color: rgb(119, 32, 32);
    margin-bottom: 1%;
    place-items: center;
    display: grid;
    grid-template-columns: 60% 40%;
    margin-left: auto;
    margin-right: auto;
    color: white;
}

@media (max-width: 1500px) {
    .manag {
        margin-top: 1%;
    }
}



/* ----------------------------------------------Kochavit------------------------------------ */
#picBussiness {
    background-position: center bottom;
}

#picBussinessOut:hover #picBussiness {
    background-position: center top;
}

.ribuha {
    width: 25%;
    height: 24vh;
    margin: 3%;
    display: inline-block;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    background-color: rgb(245, 238, 238);
    font-size: large;
    font-weight: 400;
}

.inRibuha {
    height: 12vh;
    background-color: #8d4242;
    color: white;
    padding: 0 4%;
    padding-top: 2%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inRibuhaDown {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button789 {
    width: 50%;
    margin-right: 25%;
    margin-top: 3vh;
    height: 6vh;
    line-height: 6vh;
    background-color: #8d4242;
    color: white;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.iconGlobal1 {
    padding: 2vh;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 10%;
    min-width: 160px;
    margin: 2%;
    display: inline-block;
    margin-bottom: 4%;
}

.iconGlobal1:hover {
    /* color: #c00000; */
    /* margin-top: -5vh; */
    /* margin-bottom: -5vh; */
    /* transition: margin-top 0.5s ease-in-out;     */
    cursor: pointer;
    background-color: #9a6a6a7d;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px 10px 10px 10px;
}

.containe2501 {
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 5%;
}

/* ----------------------------------------------end Kochavit------------------------------------ */

/* .kishurOption:hover {
    cursor: pointer;
    color: #C00000;
    text-decoration: underline;
} */

.menuDivBus {
    background: rgb(245, 238, 238);
    color: black;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    border-bottom: #d5baba 0.2px solid;
    direction: rtl;
    width: 23vh;
}

.menuDivBus:hover {
    background: linear-gradient(90deg, #e57373, #c00000);
    color: white;
}

.AmenuDivBus:hover {
    color: white;
}

#rightMen {
    display: inline-block;
}

@media (max-width: 768px) {
    #rightMen {
        display: none;
    }
}