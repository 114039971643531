.mainNav {
  position: fixed;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  color: black;
  z-index: 600;
  position: fixed;
  max-height: 60px;
  top: 0%;
  white-space: nowrap; /* מבטיח שכל הפריטים יישארו בשורה אחת */
  overflow-y: hidden;
  overflow-x: auto; /* מאפשר גלילה אופקית */
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch; /* תנועת גלילה חלקה למכשירים ניידים */
}

.mainNav-item {
  position: relative;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  margin-left: 25px;
  cursor: pointer;
  text-decoration: none;
}

.mainunderline {
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 0;
  height: 2px;
  background-color: #C00000;
  transition: width 0.3s ease-in-out;
}

.butConcat {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  font-weight: 400;
  position: relative;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
}

.butConcat:hover {
  background-color: #C00000;
  color: white;
}

.mainNav-item:hover .mainunderline {
  width: 100%;
}

.menuButtons:hover .mainunderline {
  width: 100%;
}

.mainNav-item:hover {
  color: #C00000;
  /* vertical-align: sub; */
}

/* .menu:hover .mainNav-item {
  color: #C00000;
} */

.mainNav-item:hover .dropdown {
  display: block;
}

/* ---------------------------- */

.menu-item {
  cursor: pointer;
}

.title {
  font-size: 130%;
  cursor: pointer;
  font-weight: 400;
}

.menuButtons {
  /* padding-left: 50px; 
  padding-right: 50px; */
  display: inline-block;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}

.menuButtons:hover {
  color: #FF7D7D;
}

.menu {
  position: absolute;
  top: 0%;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  font-size: 80%;
  padding: 2.5%;
  padding-top: 5.5%;
  /* right: 0; */
  /* transform: translateX(0%) translateY(-100%); */
  transition: transform 0.5s ease, background-color 0.7s ease;
  user-select: none;
  background-color: black;
  position: fixed;
  width: 100%;
  z-index: 500;
  /* display: block; */
}

.biUser {
  background-color: black;
  color: white;
  /* padding: 1%;
  width: 10%; */
  position: absolute;
  position: fixed;
  /* left: 20%; */
  top: 55px;
  z-index: 500;
  /* transform: translateX(0%) translateY(100%); */
}

.usefull {
  display: none;
  background-color: black;
  color: white;
  padding: 1%;
  width: 20%;
  position: absolute;
  position: fixed;
  left: 15%;
  top: 60px;
  z-index: 500;
  cursor: pointer;
  text-decoration: none;
  /* transform: translateX(0%) translateY(100%); */
}

.usefull:hover {
  color: #FF7D7D;
}

/* .visible {
  transform: translateX(0%) translateY(0%);
  background-color: black;
  position: fixed;
  width: 100%;
  z-index: 1;
} */

/* .visible1 {
  transform: translateX(-450%) translateY(0%);
} */

.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}

.mainunderline2 {
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: #FF7D7D;
  transition: width 0.3s ease-in-out, margin-right 0.3s ease-in-out;
}

.menu:hover .mainunderline2 {
  width: 10%;
  margin-right: 45%;
}

.chooze1 {
  color: #C00000;
}

.chooze2 {
  width: 100%;
}

.IconButton:hover {
  color: #C00000;
}

.notification {
  background-color: #555;
  color: white;
  /* padding: 10px 20px; */
  position: relative;
  display: inline-block;
  border-radius: 2px;
  font-size: 115%;
  padding-left: 10px;
  padding-right: 10px;
}

.notification:hover {
  background-color: #cdcccc;
}

.badge {
  position: absolute;
  height: 30px;
  line-height: 30px;
  font-size: 70%;
  top: -0px;
  right: -12px;
  padding: 1px 8px;
  border-radius: 50%;
  background-color: #c00000;
  color: white;
}

.disYes {
  display: none;
}


@media only screen and (max-width: 1690px) {
  body {
    font-size: 90%;
  }
  .mainNav-item {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 1555px) {
  .mainNav {
    font-size: 85%;
  }
  .mainNav-item {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 1310px) {
  .mainNav {
    font-size: 83%;
  }
  .mainNav-item {
    margin-left: 12px;
  }
}

/* @media only screen and (max-width: 1000px) {} */

@media (max-width: 768px) {
  .mainNav {
    font-size: 122%;
  }
  .mainNav-item {
    margin-left: 17px;
  }
  .disYes {
    display: inline-block;
  }
}