
/* #modal2 {
    position: fixed;
    z-index: 800;
    left: 0;
    top: 0;
    width: 100%;
    height: 120%;
    background-color: rgba(0, 0, 0, 0.5);
} */

.input2 {
    /* margin-top: -50px; */
    width: 120px;
    background-color: transparent;
    color: black;
    border-radius: 0px 0px 0px 0px;
    border: none;
    margin: 3px;
    border-bottom: #FF7D7D 2px solid;
    /* display: inline-block; */
    text-align: center;
}

.input2:focus {
    outline: none;
    border: #FF7D7D 2px solid;
}

.input2:hover {
    border-radius: 5px 5px 5px 5px;
}

.input20 {
    /* margin-top: -50px; */
    width: 10%;
    background-color: transparent;
    color: black;
    border-radius: 0px 0px 0px 0px;
    border: none;
    margin: 3px;
    border-bottom: #FF7D7D 2px solid;
    transition: 1s;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    text-align: center;
}

.input20:focus {
    outline: none;
}

#submit3 {
    background-color: white;
    border-radius: 5px 5px 5px 5px;
    width: 120px;
    border: #FF7D7D 2px solid;
    display: inline-block;
    position: absolute;
    top: 35px;
    left: 0;
    /* margin-top: -50px; */
}

#submit30 {
    background-color: white;
    border-radius: 5px 5px 5px 5px;
    width: 120px;
    border: #FF7D7D 2px solid;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.errorPass {
    text-decoration: underline;
}

.errorPass:hover {
    cursor: pointer;
    color: #FF7D7D;
}