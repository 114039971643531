.imageSearch {
    width: 45vh;
    height: 30vh;
    line-height: 30vh;
}

.together:hover .imageSearch {
    z-index: 30;
    color: #c00000;
    background-size: cover;
    background-position: center center;
    box-shadow: 5px 5px 10px rgba(1, 1, 1, 1);
    box-shadow: 1px 1px 10px 5px rgba(1, 1, 1, 1);

}

.together:hover .blackSearch {
    opacity: 1;
    color: white;
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: #c00000;
}

.textSearch {
    width: 30vh;
    bottom: -4vh;
    left: -3vh;
    top: auto;
}

.together:hover .border2 {
    width: 80%;
}

.together:hover .border3 {
    height: 80%;
}