.border5 {
  position: absolute;
  width: 0px;
  border: #c00000 1px solid;
  top: 65%;
  left: 20%;
  z-index: 20;
  border-right: none;
  border-left: none;
  transition: 1s;
}

.aaa:hover .border5 {
  width: 60%;
}

.border6 {
  position: absolute;
  width: 0px;
  height: 0rem;
  border: #c00000 1px solid;
  top: 65%;
  left: 70%;
  z-index: 20;
  /* border-right: none; */
  transition: 1s;
  line-height: 10rem;
}

.aaa:hover .border6 {
  height: 10rem;
  width: 10rem;
}

/* .p { */
/* animation-duration: 3s;
    animation-name: slidein;
    animation-iteration-count: infinite;
    animation-direction: alternate; */
/* animation: 3s infinite alternate slidein; */
/* animation-name: fadeInOut, moveLeft300px, bounce;
animation-duration: 2.5s, 5s, 1s;
animation-iteration-count: 2, 1, 5; */
/* animation-name: fadeInOut, moveLeft300px, bounce;
animation-duration: 3s;
animation-iteration-count: 1; */
/* } */

.p {
  animation-duration: 3s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.aaa {
  background-image: url(../../../pic/723.jpg);
  background-position-y: bottom;
  background-size: 100%;
  background-attachment: fixed;
}

.white {
  background-color: white;
}

.blackk {
  background-color: rgba(0, 0, 0, 0.82);
  width: 100%;
  color: white;
}

.whiteOp {
  background-color: rgba(255, 255, 255, 0.88);
  position: relative;
}

.border50 {
  /* position: absolute; */
  width: 70%;
  height: 2px;
  background-color: #c00000;
  left: 15%;
  z-index: 20;
  transition: 1s;
  margin-right: auto;
  margin-left: auto;
}

/* .divvv {
  background-color: rgba(255, 255, 255, 0.8); */
/* background-image: url(../../../pic/רקע.jpg); */
/* background-size: 100%; */
/* width: 55%;
  float: left;
  margin-left: 15%;
  height: 30vh;
  margin-top: 2%;
  border: #c00000 3px solid;
  margin-bottom: 3%;
} */

/* .picDivvv {
  background-image: url(../../../pic/todo.jpg);
  background-size: 150%;
  width: 14.6%;
  float: right;
  margin-right: 15%;
  height: 30vh;
  margin-top: 2%;
  margin-bottom: 3%; */
/* border: #FF7D7D 3px solid; */
/* } */

/* .divvvv { */
/* background-color: rgba(255, 255, 255, 0.8); */
/* background-image: url(../../../pic/רקע.jpg); */
/* background-size: 100%; */
/* width: 55%;
  float: right;
  margin-right: 15%;
  height: 30vh;
  margin-top: -4%;
  border: #c00000 3px solid;
} */

/* .picDivvvv {
  background-image: url(../../../pic/wedd.jpg);
  background-size: 100%;
  background-position-x: right;
  background-position-y: bottom;
  width: 14.6%;
  float: left;
  margin-left: 15%;
  height: 30vh;
  margin-top: -4%; */
/* border: #FF7D7D 3px solid; */
/* } */



.picFirst1 {
  width: 20%;
  height: 37vh;
  background-image: url(../../../pic/78.jpg);
  background-size: 100%;
  /* background-position-x: right; */
  display: inline-block;
  margin-left: 2%;
  margin-right: 8%;
  /* margin-top: -20vh;  */
  margin-bottom: 6vh;
}

.but {
  width: 70%;
  /* height: 6vh; */
  background-color: white;
  border: #c00000 4px solid;
  cursor: pointer;
  line-height: 120%;
  font-size: 80%;
  text-align: center;
}

.but:hover {
  border-color: black;
}

.first2 {
  padding-top: 20vh;
  width: 100%;
  height: 50vh;
  background-image: url(../../../pic/22.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: bottom;
  transition: 4s;
  color: white;
}

.first2:hover {
  background-position-y: top;
}

.picFirst2 {
  width: 20%;
  height: 37vh;
  background-image: url(../../../pic/animations/24.gif);
  background-size: 100%;
  /* background-position-x: right; */
  display: inline-block;
  margin-left: 2%;
  margin-right: 8%;
  /* margin-top: -20vh;  */
  margin-bottom: 6vh;
}

.picFirst3 {
  width: 20%;
  height: 37vh;
  background-image: url(../../../pic/736.jpg);
  background-size: 100%;
  /* background-position-x: right; */
  display: inline-block;
  margin-left: 2%;
  margin-right: 8%;
  /* margin-top: -20vh;  */
  margin-bottom: 6vh;
}

.picWine {
  padding-top: 20vh;
  width: 100%;
  height: 75vh;
  background-image: url(../../../pic/733.webp);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: bottom;
  transition: 4s;
}

.picWine:hover {
  background-position-y: top;
}

.picFirst4 {
  width: 17%;
  height: 37vh;
  background-image: url(../../../pic/animations/13.gif);
  background-size: 130%;
  background-repeat: no-repeat;
  background-size: 97%;
  background-position-y: center;
  background-position-x: center;
  display: inline-block;
  margin-left: 2%;
  margin-right: 8%;
  /* margin-top: -20vh;  */
  /* margin-bottom: 4vh; */
}

.picFirst5 {
  width: 20%;
  height: 71vh;
  background-image: url(../../../pic/743.webp);
  background-size: 100%;
  /* background-position-x: right; */
  float: left;
  /* display: inline-block; */
  /* margin-left: 2%; */
  /* margin-right: 8%; */
  /* margin-top: -20vh;  */
  margin-bottom: 6vh;
}

.picFirst6 {
  width: 20%;
  height: 38vh;
  background-image: url(../../../pic/745.webp);
  background-size: 100%;
  /* background-position-y: center; */
  /* background-position-x: center; */
  display: inline-block;
  margin-left: 3%;
  margin-right: 12%;
  /* margin-top: -20vh;  */
  margin-bottom: 4vh;
}

.picFirst8 {
  width: 20%;
  height: 38vh;
  background-image: url(../../../pic/animations/552.gif);
  background-size: 100%;
  /* background-position-y: center; */
  /* background-position-x: center; */
  display: inline-block;
  margin-left: 3%;
  margin-right: 12%;
  /* margin-top: -20vh;  */
  margin-bottom: 4vh;
}


.picFirst7 {
  width: 19%;
  height: 41vh;
  background-image: url(../../../pic/animations/14.gif);
  background-size: 100%;
  /* background-position-y: center; */
  /* background-position-x: center; */
  display: inline-block;
  margin-left: 3%;
  margin-right: 24%;
  /* padding-top: 5%; */
  /* margin-top: -20vh;  */
  margin-bottom: 1vh;
}

.sidenavBtn1:hover {
  color: rgb(227, 177, 177);
}

@media only screen and (max-width: 1300px) {
  .aaaaa {
    font-size: 80%;
  }

  p {
    line-height: 110%;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .left {
    margin-right: 7%;
    padding-right: 10%;
  }

  .underline3 {
    margin-top: 35px;
  }
}