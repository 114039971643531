#mySidenav2 {
    position: absolute;
    text-align: center;
    transition: 0.3s;
    width: 6%;
    font-size: 20px;
    left: 0;
    height: 200px;
}

#mySidenav2 div :hover {
    background: linear-gradient(to left, #c00000, #FF7D7D);
    color: white;
    cursor: pointer;
}

.divSide1 {
    margin-top: 3%;
    display: block;
    background-color: black;
    color: #FF7D7D;
    border-radius: 0 5px 5px 0;
    height: 40px;
    line-height: 40px;
    min-width: 45px;
}

.container3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin-right: 10%;
    margin-left: 10%;
}

.buton {
    width: 10%;
    border: #c00000 2px solid;
    color: #c00000;
    padding: 0.2rem;
    text-align: center;
    margin-top: 5rem;
    margin-right: 45%;
}

.column {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1% 0.1%;
    width: 85%;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
}

.tit {
    width: 100%;
    height: 50px;
    background-color: #c00000;
    color: white;
    border: white 3px solid;
    line-height: 50px;
    user-select: none;
}

.date {
    width: 100%;
    height: 130px;
    border: #c00000 1px solid;
    line-height: 48px;
    cursor: pointer;
}

.date2:hover .btn2 {
    display: block;
    cursor: pointer;
}

.date2 {
    width: 98%;
    margin-right: 1%;
    height: 130px;
    line-height: 47px;
}

.btn {
    width: 100%;
    height: 50px;
    color: #c00000;
    margin-top: -10px;
    line-height: 50px;
    user-select: none;
    font-weight: 400;
}

.btn2 {
    display: none;
    width: 100%;
    height: 48px;
    color: #c00000;
    margin-top: -10px;
    line-height: 50px;
    user-select: none;
    transition: margin-top 1s ease-in-out;
    transition: display 0.4s ease-in-out;
}

.kavv {
    width: 0;
    height: 1px;
    background-color: #c00000;
    transition: width 1s ease-in-out;
}

.kavv2 {
    width: 20%;
    height: 1px;
}

/* --------------------------------------כרטיסים מתהפכים------------------------------- */

.flip-box {
    /* background-color: transparent; */
    /* width: 300px;
    height: 200px; */
    /* border: 1px solid #f1f1f1; */
    perspective: 1000px;
  }
  
  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-inner {
    transform: rotateX(180deg);
  }
  
  .flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-box-front {
    color: rgb(137, 133, 133);
  }
  
  .flip-box-back {
    background-color: black;
    color: white;
    transform: rotateX(180deg);
  }
  /* -----------כרטיסים מתהפכים--------------סוף----------------------------------- */
