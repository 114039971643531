#modal2 {
    position: fixed;
    z-index: 800;
    left: 0;
    top: 0;
    width: 100%;
    height: 120%;
    background-color: rgba(0, 0, 0, 0.5);
}

#formCon2 {
    position: relative;
    width: 60%;
    background-color: white;
    height: 60%;
    margin-top: 7%;
    color: black;
    margin-left: auto;
    margin-right: auto;
    border: #c00000 2px solid;
    border-radius: 20px 20px 20px 20px;
}

.close2 {
    cursor: pointer;
    position: absolute;
    left: 12%;
    margin-top: 1.5%;
}

.close2:hover {
    color: #c00000;
    font-size: large;
}

.glow {
    background-color: white;
    width: 80%;
    height: 60vh;
    border-radius: 20px 20px 20px 20px;
    border: #c00000 3px solid;
    margin-right: 10%;
    margin-top: 20vh;
}

.titl {
    margin-top: 3%;
    font-size: 120%;
    margin-bottom: 5%;
}
