.picUp {
    width: 5rem;
    height: 5rem;
    margin-right: auto;
    margin-left: auto;
}

.choozePic {
    height: 3rem;
    /* position: absolute; */
    /* margin-top: 1rem; */
    margin-right: 1rem;
    border-radius: 0%;
    background-color: rgb(230, 227, 227);
    border: none;
}

.choozePic:hover {
    border: #c00000 1px solid;
    color: #c00000;
    color: #820909;
    background-color: rgb(216, 193, 193)
}

.searchRightBlack {
    transition: 1s;
    cursor: pointer;
    z-index: 500;
}

.searchBlack:focus {
    outline: none;
}

.divButton2 {
    margin-right: auto;
    margin-left: auto;
    background-color: #555;
}

.buttonInRibuhaDown {
    background-color: white;
}

.buttonInRibuhaDown:hover {
    background-color: #8d4242;
    color: white;
}

.alert {
    position: absolute;
    top: 8%;
    width: 20%;
    right: 40%;
    border: #c00000 2px solid;
    font-size: 25px;
    background-color: rgb(245, 238, 238);
}

.pv {
    width: auto;
    height: 35px;
    position: absolute;
    top: 8%;
    right: 40%;
    margin: 12px;
}

.iconDel_Edit {
    cursor: pointer;
    margin: 5px;

}

.iconDel_Edit:hover {
    color: #c00000;
}

.tdHowManyCall {
    padding: 0 20px;
}

.tdHowManyCall:hover {
    cursor: pointer;
    color: #c00000;
    font-weight: 400;
    padding: 0 10px;
}

.listDetails:hover {
    background-color: #FF7D7D;
}

.sticky1 {
    position: sticky;
    top: 55px;
    padding-top: 2%;
    z-index: 20;
    border-bottom: #c00000 1px solid;
    background-color: rgb(228, 208, 208);
}

.inpToNav3 {
    background-color: #555;
    text-align: center;
}

.inpToNav3Enter {
    border: #c00000 1px solid;
    background-color: rgb(218, 166, 166);
    color: #c00000;
}