/* --------------------------end Sidenav Buttons---------------------------- */

#mySidenav1 div {
  position: absolute;
  text-align: right;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  /* left: -12%; */
  transition: 0.3s;
  padding: 10px;
  width: 350px;
  /* text-decoration: none; */
  font-size: 20px;
  background-color: black;
  color: #FF7D7D;
  border-radius: 0 5px 5px 0;
  margin-top: 10%;
  left: -300px;
  padding-left: 35px;
  position: fixed;
  user-select: none;
  z-index: 300;
}

.icons {
  padding-left: 10px;
  font-size: 150%;
}

#mySidenav1 :hover {
  left: 0;
  right: 82%;
  /* background-color: #c00000; */
  background: linear-gradient(to left, #c00000, #FF7D7D);
  color: white;
  cursor: pointer;
  /* color: #c00000; */
}

#about {
  top: 20px;
  left: -12%;
}

#blog {
  top: 80px;
  left: -14%;
}

#projects {
  top: 140px;
  left: -16%;
}

#contact {
  top: 200px;
  left: -18%;
}

/* --------------------------end Sidenav Buttons---------------------------- */

/* ------------------------start table-------------------------- */
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 60%;
  margin-right: 20%;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

th,
td {
  text-align: center;
  padding: 16px;
  text-align: right;
}

/* th:first-child,
td:first-child {
  text-align: left;
} */

.class1 {
  background-color: rgb(245, 238, 238);
}

tr {
  font-weight: bold;
  /* width: 100%; */
}

.first,
.class3:hover {
  color: #c00000;
  border: 3px solid #c00000;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
}

/* .open:hover {
  border-bottom: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.open:hover .openTask {
  color: aquamarine;
  border: 3px solid #c00000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  border-top: none;
}

.openTask:hover .open {
  color: #FF7D7D;
} */

.open {
  border: 3px solid #c00000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  border-bottom: none;
  font-weight: 400;
}

.openTask {
  border: 3px solid #c00000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  border-top: none;
}

.task {
  color: black;
  border: none;
  border-top: #e6dbdb 1px solid;
}

.class3 {
  color: black;
  border: none;
  font-weight: 400;
}

.del {
  text-align: right;
  position: absolute;
  bottom: 30px;
}

.del:hover {
  color: #c00000;
}

.buton1 {
  width: 10%;
  border: #c00000 2px solid;
  /* border-radius: 20px 20px 20px 20px; */
  color: #c00000;
  padding: 0.2rem;
  text-align: center;
  margin-top: 0.5rem;
  /* margin-right: 45%; */
}

.buton5 {
  width: 10%;
  border: #c00000 2px solid;
  /* border-radius: 20px 20px 20px 20px; */
  color: #c00000;
  padding: 0.2rem;
  text-align: center;
  margin-top: 0.5rem;
  /* margin-right: 45%; */
}


/* ------------------------end table-------------------------- */


.kishur:hover {
  color: #c00000;
  font-weight: 400;
}

/* ------------------------begin AlertAddPersonalTask-------------------------- */

.inp {
  border: transparent;
  border-bottom: #FF7D7D 2px solid;
  width: 28%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  margin-top: 1%;
  min-width: 28%;
  transition: 1s;
  user-select: none;
}

.checkbox-container0 {
  display: inline-block;
  cursor: pointer;
  text-align: right;
  font-size: 95%;
}

.checkbox-custom0 {
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 3px;
  color: transparent;
  line-height: 20px;
  padding: 4px;
  margin: 4px;
  user-select: none;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 15px;
}

.checkbox-container0:hover .checkbox-custom0 {
  background-color: #ccc;
}

.checkbox0:checked+.checkbox-custom0,
.checkbox00:checked+.checkbox-custom0 {
  background-color: #fe7b7b;
  color: #c00000;
}

.checkbox0:checked+.checkbox-custom0 .checkbox-custom0 {
  background-color: #eee;
  color: #eee;
}

.checkbox0,
.checkbox00 {
  display: none;
}


/* ------------------------end AlertAddPersonalTask-------------------------- */

.divButton {
  width: 80%;
  user-select: none;
  cursor: pointer;
  color: rgb(249, 248, 248);
  background-color: rgb(249, 248, 248);
  border: rgb(249, 248, 248) 1px solid;
  transition: 1s;
  text-align: center;
}

.divButton:hover {
  border: #c00000 1px solid;
  background-color: rgb(218, 166, 166);
  color: #c00000;
  text-align: center;
}

.buttonKishur {
  /* background: linear-gradient(90deg, white ,#f2bbbb); */
  color: #c00000;
  font-weight: 400;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  border: #c00000 2px solid;
  /* color: #8d0707; */
}

.buttonKishur:hover {
  color: white;
  background: linear-gradient(90deg, #e57373 ,#c00000);
  /* border-color: white; */
}

.glow78 {
  height: 70vh;
  margin-top: 15vh;
}

.proPic2 {width: 23%;}

.proAll {height: 12vh;}

#toHelp {
  width: 90%;
  margin-right: 10%;
} 

@media only screen and (max-width: 1300px) {
  .buton1 {
    font-size: 90%;
  }

  #toHelp {
    font-size: 90%;
    width: 95%;
    margin-right: 5%;
  }

  /* .prudu2 {
    width: 100%;
    height: fit-content;
  } */
/* 
  .proPic2 {
    width: 150%;
  } */

  .proAll {
    font-size: 90%;
    height: 100px;
  }
}

@media only screen and (max-height: 620px) {
  .form78 {
    font-size: 95%;
  }

  .tit78 {
    margin-top: -4%;
  }

  .glow78 {
    height: 90vh;
    margin-top: 5vh;
  }

}