.inp:focus {
    outline: none;
}

.a4 {
    width: 8.27in;
    height: 11.9in;
    border: black 1px solid;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1.5%;
}

.inpu {
    background-color: rgb(230, 227, 227);
    text-align: center;
    padding: 3%;
    margin: -2%;
}

.inpu:focus {
    outline-color: #c00000;
}

#example {
    transition: 2s;
}

#tableAddress {
    transition: 1.5s;
}