.ckav {
    height: 10%;
    width: 2px;
    background-color: black;
    margin-right: 60%;
    transition: 1s;
}

.egul {
    height: 70px;
    width: 70px;
    margin-right: 48%;
    border-radius: 50%;
    border: black 2px solid;
    transition: 1s;
}

.glo1 {
    box-shadow: 0 0 2px 0.5px #c00000;
}

.glo2 {
    box-shadow: 0 0 4px 1px #c00000;
}

.ii {
    border-bottom: #c00000 2px solid;
}

.ii:hover {
    /* margin: 20px; */
    border-bottom: #f5afaf 2px solid;
}

.remove:hover {
    cursor: pointer;
    border: #c00000 6px solid;
}

.remove2:hover {
    cursor: pointer;
    border: rgb(238, 167, 167) 8px solid;
}

.remove,
.remove2 {
    transition: 0.3s;
}

textarea {
    outline-color: #c00000;
    /* border: black 2px solid; */
}

.in1 {
    width: 40vh;
}

.in2 {
    width: 8%;
}

#search1 {
    border-bottom: #c00000 2px solid;
    width: 50%;
    margin-right: auto;
    margin-left: auto;
}

.buton {
    min-width: max-content;
}

/* -----------------------------------------------------------------------
                                start select
----------------------------------------------------------------------- */
.custom-select1 {
    width: 70%;
    margin-top: 5%;
    height: 5vh;
    text-align: center;
    border: #c00000 1px solid;
}

.custom-select1:after {
    border: #c00000 2px solid;
}

option {
    border: #c00000 1px solid;
    height: 5px;
    font-weight: 300;
}

option:hover {
    background-color: #942424;
}


/* -----------------------------------------------------------------------
                                end select
----------------------------------------------------------------------- */

.closeCategory {
    color: #555;
}

.closeCategory:hover {
    color: #c00000;
}

.ii:-webkit-autofill,
.ii:-webkit-autofill:hover,
.ii:-webkit-autofill:focus,
.ii:-webkit-autofill:active {
    /* font-weight: 300 !important; */
    background-color: white !important;
    transition: 5000s ease-in-out 0s;
    /* color: white !important; */
}

