.first1 {
  height: 60vh;
  width: auto;
  min-width: 100%;
  background-image: url(../../../pic/23.jpg);
  background-size: 100%;
  background-repeat: round;
  background-position-y: center;
  float: left;
  transition: 7s;
  color: white;
  margin-bottom: 10px;
}

.first1:hover {
  background-position-y: top;
}

.second1 {
  width: 144%;
  height: 70vh;
  transform: translateX(30%) translateY(0%);
  transition: 6s;
  -webkit-user-drag: none;
  overflow-x: hidden;
}

.second2 {
  width: 144%;
  display: flex;
  align-items: center;
}

.second2:hover .second1 {
  transform: translateX(0%) translateY(0%);
}

.zoom {
  position: relative;
  background-color: white;
  display: inline-block;
  border: #c00000 1px solid;
  transition: transform 0.2s;
  width: 500px;
  height: 200px;
  /* margin: 0 auto; */
  user-select: none;
  color: rgb(106, 26, 26);
  /* animation: bounce 0.5s alternate infinite ease; */
}

.zoom:hover {
  -ms-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.inNiulYoman {
  display: inline-block;
  padding: 2%;
  width: 26%;
  height: 210px;
  margin: 0.2%;
  margin-bottom: 3%;
  transition: 0.5s;
}

.inNiulYoman:hover {
  background-color: white;
  border: #6A1A1A 1px solid;
}

.oneTWOtree {
  background-color: white;
  transition: 0.2s;
  padding: 3%;
}

/* .oneTWOtree:hover {
    background-color: black;
    padding-right: 20%;
  } */

.buttonContain {
  width: 100%;
  font-weight: 600;
  background-color: #c00000;
  color: white;
  height: 6vh;
  line-height: 6vh;
  cursor: pointer;
  box-shadow: 3px 10px 8px rgba(0, 0, 0, 0.5);
  transition: 0.2s;
}

.buttonContain:hover {
  box-shadow: 8px 12px 8px rgba(0, 0, 0, 0.7);
}

.gridTempAdd {
  grid-template-columns: repeat(2, 5fr);
}

.tableToPrice {
  width: 85%;
}

.toSmallFont1 {
  font-size: 150%;
}

.toSmallFont2 {
  margin-top: -4%;
  font-size: 190%;
}

.toSmallFont3 {
  font-size: 250%;
}

.kochavitFields::placeholder {
  color: #c00000;
}

/* ///////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////// */

@media only screen and (max-width: 1800px) {
  .container58 {
    font-size: 70%;
  }

  #free2 {
    font-size: 120%;
  }

}

/* מדיה קווריז */
@media (max-width: 768px) {
  body {
    font-size: 60%;
  }

  .first1 {
    height: 54vh;
    background-size: contain;
    padding: 6%;
    padding-top: 22vh;
    font-size: 70%;
  }

  .butContain {
    font-size: 150%;
  }

  .buton1 {
    top: -3%;
    background-color: #c00000;
    z-index: 5;
  }

  .second1 {
    /* margin-top: 8%; */
    height: 35%;
    transform: none;
  }

  .second2 {
    margin-top: 12%;
    margin-bottom: 7vh;
  }

  .second2 p {
    padding: 3%;
    font-size: 80%;
  }

  .toSmallFont1 {
    margin-top: 12vh;
    font-size: 120%;
  }

  .toSmallFont2 {
    margin-top: -2%;
    font-size: 140%;
  }

  .toSmallFont3 {
    font-size: 180%;
  }

  .egulim {
    display: none;
  }

  .zoom {
    padding: 0%;
  }

  .tableToPrice th {
    width: 20%;
  }

  #downMore,
  .egul,
  .ckav {
    display: none;
  }

  #bottomMenu {
    height: 45rem;
    background-color: black;
  }

  .containerBottom {
    grid-template-columns: repeat(3, 33%);
  }

  .inContainerBottom {
    margin-top: 25%;
  }

  .inPic {
    margin-top: 15vh;
  }

  .kol {
    transform: translateY(-30%);
  }

  #brButton {
    display: none;
  }

  #imgKol {
    margin-top: 0;
  }

  .card {
    width: 12vh;
  }

  .addB {
    padding-top: 27%;
    margin-bottom: 10%;
  }

  .addGlow {
    padding-bottom: 30%;
  }

  .gridTempAdd {
    grid-template-columns: repeat(1, 5fr);
  }

  .titlADD {
    padding-top: 30%;
  }

  #buttonColor,
  #button {
    z-index: 0;
  }

  #fileList12 ::-webkit-scrollbar {
    width: 40px;
  }

  th,
  td {
    padding: 6px;
  }

  .tableToPrice {
    width: 95%;
  }

  audio {
    width: 150px;
    /* רוחב הנגן */
    height: 30px;
    /* גובה הנגן */
  }

  .column {
    font-size: 80%;
  }

}