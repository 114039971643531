.cardImg {
    position: relative;
    margin: 5%;
    display: inline-block;
    transition: 1s;
}

.img {
    border-radius: 5px 5px 5px 5px;
    width: 200px;
    height: 240px;
    z-index: 1;
    background-size: cover;
    background-position: bottom left;
    transition: background-position 0.4s ease-in-out;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

}

.img:hover {
    background-size: cover;
    background-position: top right;
    box-shadow: 0 2px 10px rgba(1, 1, 1, 1);
}

#txt {
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    padding: 5px;
    position: absolute;
    top: 20px;
    z-index: 2;
    font-weight: 400;
}

.img:hover #txt {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
}

/* .underlineRed {
    margin-top: -35px;
    margin-bottom: 40px;
    width: 80px;
    height: 4px;
    background-color: #C00000;
    margin-left: auto;
    margin-right: auto;
} */

.title789 {
    padding-top: 10%;
}

@media only screen and (max-width: 1300px) {
    .title789 {
        padding-top: 12%;
    }
}