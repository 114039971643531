/* -----------------------------start form----------------------------------- */
.glow {
    box-shadow: 0 0 10px 5px gray;
    transition: box-shadow 0.3s ease-in-out;
}

.glow:hover {
    box-shadow: 0 0 10px 5px #c00000;
}

.glow:hover .titl {
    font-weight: 400;
}

.input {
    border-radius: 25px 25px 25px 25px;
    padding: 0.5%;
    padding-right: 1%;
    direction: rtl;
    width: 250px;
    display: inline-block;
    border: black 2px solid;
    margin: 5px;
    margin-bottom: 45px;
}

.input:hover {
    border: #C00000 2px solid;
}

/* option {
    color: #C00000;
} */

#submit {
    background-color: black;
    color: white;
}

#submit:hover {
    border: #FF7D7D 2px solid;
    color: #FF7D7D;
}
.input1 {
    width: 230px;
    padding: 0.3%;
    padding-right: 1%;
    background-color: black;
    color: white;
    border-color: white;
    /* border: white 2px solid; */
}

.input1:hover {
    border-color: #FF7D7D;
}

.submit1 {
    background-color:white;
    color:black;
}

.submit1:hover {
    color: #FF7D7D;
    background-color: black;
    border-color: #FF7D7D;
}

.logo {
    height: 5rem;
    width: auto;
    margin: 1rem;
}

.send {
    position: absolute;
    left: 35%;
    top: 7vh;
    width: 30%;
    height: 8vh;
    position: fixed;
    background-color: white;
    z-index: 9999;
    border: rgb(128, 14, 14) 3px solid;
}
