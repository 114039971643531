#button {
    height: 45px;
    z-index: 1;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    direction: rtl;
    color: black;
    cursor: pointer;
    user-select: none;
    position: relative;
}

#buttonColor {
    background: linear-gradient(to left, #c00000, #FF7D7D);
    height: 40px;
    width: 0;
    z-index: 900;

}

#button:hover {
    color: white;
    font-weight: 400;
}