#bottomMenu {
    background-image: url(../../../pic/pic5.png);
    width: 100%;
    height: 30rem;
    margin-top: 5%;
    position: relative;
    z-index: 220;
    bottom: 0%;
    /* color: white; */
}

#bottomMenu span:hover, a:hover {
    color: #FF7D7D;
}

.containerBottom {
    margin-right: 5%;
    display: grid;
    grid-template-columns: repeat(3, 14%);
    /* grid-gap: 20px; */
}

.linkBottom {
    text-decoration: none;
}

#bottomMenu .linkBottom:hover {
    color: #FF7D7D;
}

.inContainerBottom {
    margin-top: 20%;
}

@media only screen and (max-width: 1650px) {
    #bottomMenu {
        height: 37rem;
    }
    .containerBottom {
        margin-right: 0%;
        grid-template-columns: repeat(3, 20%);
    }
    .inContainerBottom {
        margin-top: 50%;
    }
}

@media only screen and (max-height: 600px), (max-width: 1380px) {
    #bottomMenu {
        height: 42rem;
    }
    .inContainerBottom {
        margin-top: 65%;
    }
}

