/* ----------------------------start top button---------------------------- */
#topBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 800;
    font-size: 18px;
    border: none;
    outline: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    background-color: #C00000;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
  }
  
  #topBtn:hover {
    background-color: black;
  }
  
  /* ----------------------------end top button---------------------------- */