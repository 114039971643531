#modal {
    position: fixed;
    z-index: 800;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    user-select: none;
}

#formCon {
    width: 100%;
    background-color: black;
    height: 60%;
    margin-top: 10%;
    color: white;
    position: relative;
}

.bottom5 {
    background-color: #FF7D7D;
    margin-top: 25px;
}

.input4 {
    border-radius: 0px 0px 0px 0px;
    background-color: transparent;
    color: white;
    border-bottom: #FF7D7D 2px solid;
    display: block;
    width: 200px;
    margin-top: 5%;
}

.input4:hover {
    border-radius: 5px 5px 5px 5px;
    border: #FF7D7D 2px solid;
}

.container2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    display: flex;
    justify-content: center;
    /* direction: rtl;
    float: right; */
    /* text-align: center; */

}

.close:hover {
    color: #FF7D7D;
    font-size: large;
}

@media (max-width: 1700px) {
    .pInDiv {
        margin-left: -7%;
    }

}

@media (max-width: 1000px) {
    .pInDiv {
        margin-left: -15%;
    }
}


