#modal {
    position: fixed;
    z-index: 800;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

#formCon5 {
    width: 100%;
    background-color: black;
    height: max-content;
    padding-bottom: 3%;
    margin-top: 10vh;
    color: white;
}

.bottom5 {
    background-color: #FF7D7D;
    margin-top: 25px;
}

.input4 {
    border-radius: 0px 0px 0px 0px;
    background-color: transparent;
    color: white;
    border: transparent;
    border-bottom: #FF7D7D 2px solid;
    display: block;
    width: 50vh;
    margin-top: 5%;
    font-size: 90%;
}

.input4:hover {
    border-radius: 5px 5px 5px 5px;
    border: #FF7D7D 2px solid;
}

.in7:-webkit-autofill,
.in7:-webkit-autofill:hover,
.in7:-webkit-autofill:focus,
.in7:-webkit-autofill:active {
    font-weight: 300 !important;
    background-color: #000000 !important;
    transition: 5000s ease-in-out 0s;
    color: white !important;
}

.in8 {
    border-radius: 0px 0px 0px 0px;
    background-color: transparent;
    color: white;
    border: transparent;
    border-bottom: #FF7D7D 2px solid;
    display: block;
    width: 45vh;
    margin-top: 1%;
    margin-right: auto;
    margin-left: auto;
    font-size: 90%;
}

.in8:hover {
    border-radius: 5px 5px 5px 5px;
    border: #FF7D7D 2px solid;
}

.container88 {
    /* display: grid;
    grid-template-columns: repeat(3);
    column-width: 120%;
    grid-gap: 10px; */
    display: flex;
    justify-content: center;
    padding: 0 2%;

    /* direction: rtl;
    float: right; */
    /* text-align: center; */

}

.close:hover {
    color: #FF7D7D;
    font-size: large;
}

.or {
    transition: 1s;
    color: white;
    border: none;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
}

.or:hover {
    color: #FF7D7D;
    border: white 1px solid;
}

.orClick {
    color: #FF7D7D;
    border: white 1px solid;
}

.containe250 {
    display: grid;
    grid-gap: 5vh;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 12vh;
}

.buttonRed {
    min-width: max-content;
}

.iconGlobal:hover {
    color: #c00000;
    margin-top: -5vh;
    margin-bottom: -5vh;
    transition: margin-top 0.5s ease-in-out;
    cursor: pointer;
}

.chetz {
    margin-top: 6%;
}

.pRight {
    bottom: 0px;
    width: 22%;
    font-size: 85%;
}

.theModal2 {
    width: 100%;
    background-color: black;
    height: 70%;
    margin-top: 7.5%;
    color: white;
}

.btnCon {
    bottom: -5%;
}

.checkbox-container44 {
    display: inline-block;
    position: relative;
    padding-left: 25px;
    /* margin-bottom: 10px; */
    cursor: pointer;
    text-align: right;
    font-size: 95%;
    /* margin-right: -30%; */
    cursor: pointer;
    position: absolute;
    right: 25px;
}

#down45 {
    width: 50%
}

#all44 {
    font-size: 110%;
    width: 50%
}

#toMarginHr {
    margin-top: 140px;
}

@media only screen and (max-height: 640px) {
    .btnCon {
        bottom: 5%;
    }
}

@media only screen and (max-width: 1000px) {
    #modal {
        background-color: black;
    }

    .chetz {
        padding-top: 30%;
        height: 200%;
    }
}

@media only screen and (max-width: 1500px) {
    .chetz {
        margin-top: 19%;
    }

    .pRight {
        width: 19%;
    }
}

@media only screen and (max-height: 720px) {
    #formCon5 {
        margin-top: 3%;
    }

    .theModal2 {
        margin-top: 3%;
        height: 85%;
    }

    
}

@media (max-width: 768px) {
    .isShow55 {
        display: none;
    }

    #down45 {
        width: 90%;
        font-size: 130%;
    }

    #all44 {
        font-size: 140%;
        width: 100%;
    }

    #toMarginHr {
        margin-top: 250px;
    }
}